import UsersPageComponent from "./components/UsersPageComponent";

import axios from "axios";

const fetchUsers = async (abctrl) => {
    console.log("user data request ")
    const {data} = await axios.get(process.env.REACT_APP_BASE_URL+"/api/users");
    console.log("user data response ", data)
    return data
}

const deleteUser = async (userId) => {
    const { data } = await axios.delete(process.env.REACT_APP_BASE_URL+"/api/users/"+ userId);
    return data
}

const AdminUsersPage = () => {
  return <UsersPageComponent fetchUsers={fetchUsers} deleteUser={deleteUser} />;
};

export default AdminUsersPage;

