import { Row, Col, Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { logout } from "../../../redux/slices/user/userSlice";
import { useDispatch } from "react-redux";

const ProductsPageMobComponent = ({ fetchProducts, deleteProduct, searchProductRecord }) => {
  const [products, setProducts] = useState([]);
  const [ expend, setExpend ] = useState(false);
  const [ expendRowId, setExpendRowId ] = useState(0);
   const [productDeleted, setProductDeleted] = useState(false); 
   const dispatch = useDispatch();
   const { searchQuery } = useParams();

  // console.log(searchQuery);
   useEffect(()=>{

    if(searchQuery){
      searchProductRecord(searchQuery).then((responce) => {
        if (responce) {
       //   console.log(responce);
          setProducts(responce.data)
         }
      })
      .catch((err) => {
        console.log(err);
      });
  
     }
   },[searchQuery])
  

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure?")) {
        const data = await deleteProduct(id)
        if (data.message === "product removed") {
            setProductDeleted(!productDeleted);
        }
    }
  };

  const handlerExpendRow = (id) =>{
    console.log(id)
    setExpend(!expend);
    setExpendRowId(id);
  }

  useEffect(() => {
    const abctrl = new AbortController();
    if(!searchQuery){
    fetchProducts(abctrl)
      .then((res) => setProducts(res))
      .catch((er) =>
      dispatch(logout())
        // setProducts([
        //   {name: er.response.data.message ? er.response.data.message : er.response.data}
        // ])
      );
    }
    return () => abctrl.abort();
  }, [productDeleted]);
//console.log(products)
  return (
    <Row className="m-5">
      <Col md={2}>
        <AdminLinksComponent />
      </Col>
      <Col md={10}>
        <h1>
          Product List{" "}
          <LinkContainer to="/admin/create-new-product">
            <Button variant="primary" size="lg">
              Create new
            </Button>
          </LinkContainer>
        </h1>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Picture</th>
              <th>Product Name</th>
              {/* <th>Location</th>
              <th>Stock</th>
              <th>Price</th>
              <th>Category</th>
              <th>Code</th>
              <th>Edit/Delete</th> */}
            </tr>
          </thead>
          <tbody>
            {products.map((item, idx) => (<>
              <tr key={idx}>
                {/* <td>{idx + 1}</td> */}
                <td>{expend&&(expendRowId===item.id)?<button style={{fontSize:14, backgroundColor:'red', border:0, color:'white',borderRadius:40,}}onClick={()=>{handlerExpendRow(item.id)}}>-</button>
                :<button style={{fontSize:14, backgroundColor:'#20b55c', border:0, color:'white',borderRadius:40,}}onClick={()=>{handlerExpendRow(item.id)}}>+</button>}</td>
               
                <td><img style={{height: 50, fontSize: 24, margin: '0 auto', textAlign: 'center'}} src={((Number(item.id))<430)?`/${item.image}`:item.image} /></td>
                <td>{item.description}</td>
                {/* <td>{item.location}</td>
                <td>{item.count}</td>
                <td>{item.price}</td>
                <td>{item.idCategory}</td>
                <td>{item.code}</td>
                <td>
                  <LinkContainer to={`/admin/edit-product/${item.id}`}>
                    <Button className="btn-sm">
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </LinkContainer>
                  {" / "}
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(item.id)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </Button>
                </td> */}
                <td></td>
              </tr>
{
expend && (expendRowId===item.id)&&(<>
<tr><td>Location</td><td>{item.location}</td></tr>
<tr><td>Stock</td><td>{item.count}</td></tr>
<tr><td>Buying Price</td><td><span>&#163;</span>{item.price}</td></tr>
<tr><td> Selling Price</td><td><span>&#163;</span>{item.sellingPrice}</td></tr>
<tr><td>Category</td><td>{item.idCategory}</td></tr>
<tr><td>Code</td><td>{item.code}</td></tr>
<tr><td>Date added</td><td>{item.date}</td></tr>
<tr><td>Action</td> <td>
                  <LinkContainer to={`/admin/edit-product/${item.id}`}>
                    <Button className="btn-sm">
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </LinkContainer>
                  {" "}
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(item.id)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </Button>
                </td></tr>
</>)
}


</>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ProductsPageMobComponent;

