import axios from "axios";

export const uploadImagesApiRequest = async (images, productId) => {
    console.log(images)
  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
  console.log(formData)
  const { data } = await axios.post(process.env.REACT_APP_BASE_URL+"/api/products/admin/upload?productId=" + productId, formData);
  console.log("image save response ", data)
  return data;
}; 

export const uploadImagesCategoryApiRequest = async (images, productId) => {
    console.log(images)
  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
  console.log(formData)
  //formData.append("productId", productId);
  const { data } = await axios.post(process.env.REACT_APP_BASE_URL+"/api/categories/admin/upload?productId=" + productId, formData);
  console.log("image save response ", data)
  return data;
};

export const uploadImagesCloudinaryApiRequest = (images,productId) => {
    const url = "https://api.cloudinary.com/v1_1/jkhbnc-366gs/image/upload";
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append("file", file);
        formData.append("upload_preset", "acsijtdn");
        fetch(url, {
            method: "POST",
            body: formData,
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            axios.post(process.env.REACT_APP_BASE_URL+"/api/products/admin/upload?cloudinary=true&productId=" + productId, data);
        })
    }
}
