import LoginPageComponent from "./components/LoginPageComponent";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { setReduxUserState } from "../redux/slices/user/userSlice";
//console.log("login page ",process.env.REACT_APP_BASE_URL);
const loginUserApiRequest = async (email, password, doNotLogout) => {
    const { data } = await axios.post(process.env.REACT_APP_BASE_URL+"/api/users/login", { email, password, doNotLogout }, { withCredentials: true });
    if (data.userLoggedIn.doNotLogout) localStorage.setItem("userInfo", JSON.stringify(data.userLoggedIn));
    else sessionStorage.setItem("userInfo", JSON.stringify(data.userLoggedIn));
     return data;
}

const LoginPage = () => {
 const navigate = useNavigate();

  useEffect(()=>{
if(localStorage.getItem("userInfo")){
   const items = JSON.parse(localStorage.getItem('userInfo'));
  console.log("work", items.name)


  

  if (items.name === "admin"){ 
    reduxDispatch(setReduxUserState(items));
    navigate("/admin/products")
   
  }
  else{
    navigate("/user")
 
  }
  
}

  },[])

    const reduxDispatch = useDispatch();
  
  return <LoginPageComponent loginUserApiRequest={loginUserApiRequest} reduxDispatch={reduxDispatch} setReduxUserState={setReduxUserState} />
};

export default LoginPage;

