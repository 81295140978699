import UserOrdersPageComponent from "./components/UserOrdersPageComponent";
import axios from "axios";

const getOrders = async () => {
  console.log("user orders")
    const { data } = await axios.get(process.env.REACT_APP_BASE_URL+"/api/orders");
    return data;
}

const UserOrdersPage = () => {
  return <UserOrdersPageComponent getOrders={getOrders} />;
};

export default UserOrdersPage;

