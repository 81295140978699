import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductsPageComponent from "./components/ProductsPageComponent";
import ProductsPageMobComponent from "./components/ProductsPageMobComponent";
import axios from "axios";


const fetchProducts = async (abctrl) => {
    const { data } = await axios.get(process.env.REACT_APP_BASE_URL+"/api/products/admin", {
        signal: abctrl.signal,
    })
    return data;
}

const deleteProduct = async (id) => {
    const { data } = await axios.delete(process.env.REACT_APP_BASE_URL+"/api/products/admin/"+id);
    return data
}
const searchProductRecord = (serchText) =>{
  const data = axios.get(process.env.REACT_APP_BASE_URL + "/api/products/admin/search", {
      params: { search_text: serchText },
    })
    return data;
}

const AdminProductsPage = () => {
//     const { searchQuery } = useParams();
// console.log(searchQuery);
// if(searchQuery){searchProductRecord(searchQuery)}
const [mobTable, setMobTable] = useState(false);
useEffect(()=> {
const windowSize = window.innerWidth;
if(windowSize>900){setMobTable(true)}else{setMobTable(false)}
//console.log(windowSize);
},[])


// return  mobTable ? ( <ProductsPageMobComponent fetchProducts={fetchProducts} deleteProduct={deleteProduct} searchProductRecord={searchProductRecord} />)
// : ( <ProductsPageComponent fetchProducts={fetchProducts} deleteProduct={deleteProduct} searchProductRecord={searchProductRecord} />)
 
return   ( <ProductsPageMobComponent fetchProducts={fetchProducts} deleteProduct={deleteProduct} searchProductRecord={searchProductRecord} />)

};

export default AdminProductsPage;

