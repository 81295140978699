import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "./slices/counter/counterSlice";
import colorSlice from "./slices/color/colorSlice";
import  userSlice  from "./slices/user/userSlice";
import cartSlice     from "./slices/cart/cartSlice";
import categorySlice from "./slices/category/categorySlice";

export  const store = configureStore({
    reducer:{
        counter: counterSlice,
        color: colorSlice,
        user: userSlice,
        cart: cartSlice,
        categories: categorySlice
    },
});

export default store;
