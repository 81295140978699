import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
  Table,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeCategory, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";

const CreateCategoryPageComponent = ({
  createProductApiRequest,
  uploadImagesCategoryApiRequest,
  uploadImagesCloudinaryApiRequest,
  categories,
  reduxDispatch,
  newCategory,
  deleteCategory,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateProductResponseState] = useState({
    message: "",
    error: "",
  });
  const [categoryChoosen, setCategoryChoosen] = useState("Choose category");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const nameRef = useRef();
  const imageRef = useRef();
  const descRef = useRef();
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      name: form.name.value,
    //  description: form.description.value,
    //  count: form.count.value,
    //  price: form.price.value,
    //  category: form.category.value,
    //  attributesTable: attributesTable,
    };

    createProductApiRequest(formInputs)
    .then((data) => {
      console.log(data);
if(data.message==='category created'){
      nameRef.current.value = "";
}

    })

  }

  const uploadHandler = (images) => {
    setImages(images);
  };

  const newCategoryHandler = (e) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value) {
      reduxDispatch(newCategory(e.target.value));
      setTimeout(() => {
        let element = document.getElementById("cats");
        setCategoryChoosen(e.target.value);
        element.value = e.target.value;
        e.target.value = "";
      }, 200);
    }
  };

  const deleteCategoryHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteCategory(element.value));
    setCategoryChoosen("Choose category");
  };

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);

  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
           if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, categoryChoosen));
               setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
               e.target.value = "";
               createNewAttrKey.current.value = "";
               createNewAttrVal.current.value = "";
               setNewAttrKey(false);
               setNewAttrValue(false);
           }

      }
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/category" className="btn btn-info my-3">
            Go Back
          </Link>
        </Col>
        <Col md={6}>
          <h1>Create a new category</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control name="name" ref={nameRef} required type="text" />
            </Form.Group>

            {/* <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="description"
                ref={descRef}
                required
                as="textarea"
                rows={3}
              />
            </Form.Group>
        
            <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label>Images</Form.Label>

              <Form.Control
                required
                ref={imageRef}
                type="file"
                multiple
                onChange={(e) => uploadHandler(e.target.files)}
              />
              {isCreating}
            </Form.Group> */}
            <Button variant="primary" type="submit">
              Create
            </Button>
            {createProductResponseState.error ?? ""}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateCategoryPageComponent;

