import { createSlice } from "@reduxjs/toolkit";

const cartItemsLS = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const itemsCountLS = cartItemsLS
  ? cartItemsLS.reduce((quantity, item) => Number(item.quantity) + quantity, 0)
  : 0;
const cartSubtotalLS = cartItemsLS
  ? cartItemsLS.reduce((price, item) => price + item.price * item.quantity, 0)
  : 0;

const initialState = {
  cartItems: cartItemsLS,
  itemsCount: itemsCountLS,
  cartSubtotal: cartSubtotalLS,
};

export const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      var productBeingAddedToCart = action.payload;

      const currentState = { ...state };
      // const currentStateItam = state.cartItems
      // const currentStateItam1 = [...currentStateItam]
     

      
      //currentState.cartItems = [...state.cartItems, productBeingAddedToCart]; // also works
console.log("cartItemsArray ", cartItemsLS)

     // console.log(productBeingAddedToCart.id);
      const productAlreadyExistsInState = state.cartItems.find(
        (x) => x.id === productBeingAddedToCart.id
      );
    

      if (productAlreadyExistsInState) {
        console.log("duplicate")
        currentState.itemsCount = 0;
        currentState.cartSubtotal = 0;
        currentState.cartItems = cartItemsLS.map((x) => {
          console.log("currentState old ", x)
            if (x.id === productAlreadyExistsInState.id) {
                currentState.itemsCount += Number(productBeingAddedToCart.quantity);
                const sum = Number(productBeingAddedToCart.quantity) * Number(productBeingAddedToCart.price);
                currentState.cartSubtotal += sum;
            } else {
                currentState.itemsCount += Number(x.quantity);
                const sum = Number(x.quantity) * Number(x.price);
                currentState.cartSubtotal += sum;
            }
            return x.id === productAlreadyExistsInState.id ? productBeingAddedToCart : x;
        });
      

        state = {
          ...state,
          cartItems: currentState.cartItems,
          itemsCount: currentState.itemsCount,
          cartSubtotal: currentState.cartSubtotal,
        };
        localStorage.setItem("cart", JSON.stringify(state.cartItems));
        return state;

      } // end of duplicate product
      else {
        const cartItemsArray = [...state.cartItems, productBeingAddedToCart];
        //currentState.cartItems = [...state.cartItems, productBeingAddedToCart]; // also works
console.log("cartItemsArray ", cartItemsArray)
        currentState.itemsCount += Number(productBeingAddedToCart.quantity);
        const sum =
          Number(productBeingAddedToCart.quantity) *
          Number(productBeingAddedToCart.price);
        currentState.cartSubtotal += sum;

        state = {
          ...state,
          cartItems: cartItemsArray,
          itemsCount: currentState.itemsCount,
          cartSubtotal: currentState.cartSubtotal,
        };
        localStorage.setItem("cart", JSON.stringify(state.cartItems));
        return state;
      } // end of if not duplicat
    }, // end of add products

    removeFromCart: (state, action) => {
      console.log("action.payload.id ", action.payload)
      state = {
        ...state,
        cartItems: state.cartItems.filter(
          (x) => x.id !== action.payload.id
        ),
        itemsCount: state.itemsCount - action.payload.quantity,
        cartSubtotal:
          state.cartSubtotal - action.payload.price * action.payload.quantity,
      };
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
      return state;
    },
    orderSubmited: (state, action) => {
      console.log("action.payload.id ", action.payload)
      state = {
        ...state,
        cartItems: [],
        itemsCount: 0,
        cartSubtotal:0,
      };
      localStorage.removeItem("cart");
      return state;
    },
  },
});

export const { addToCart, removeFromCart, orderSubmited } = cartSlice.actions;
export default cartSlice.reducer;
