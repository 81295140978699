import { createSlice } from "@reduxjs/toolkit";

const initialState ={
   
    categories:[]
};

const categorySlice = createSlice({
    name:'category1',
    initialState,
    reducers:{
        getCategories:(state, action)=>{
        //    console.log("in slice ",action.payload)
            return {...state, categories:action.payload}
        },	
    
        newCategory:()=>{},
        deleteCategory:()=>{},
        saveAttributeToCatDoc:()=>{},




    }
})

export const {getCategories,newCategory,deleteCategory, saveAttributeToCatDoc } = categorySlice.actions;
export default categorySlice.reducer;