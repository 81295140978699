import { createSlice } from "@reduxjs/toolkit";



const initialState ={
   userInfo :  localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : sessionStorage.getItem("userInfo")
    ? JSON.parse(sessionStorage.getItem("userInfo"))
    : {  name:'',
    lastName:'',
    email:'',
    isAdmin: '',
    phoneNumber: '',
    address: '',
    country: '',
    zipCode: '',
    city: '',
    state: '',
}
}


 const userSlice = createSlice ({  
   
    name : 'user',
    initialState,
    reducers:{
        setReduxUserState:(state, action)=>{
            console.log("in slice ",action.payload)
            return { ...state, userInfo:action.payload}
        },
        logout:(state)=>{
            
            document.location.href = "/";
 
  
    localStorage.removeItem("userInfo");
    sessionStorage.removeItem("userInfo");
    localStorage.removeItem("cart");
   // dispatch({ type: LOGOUT_USER }) 
            
            return {userInfo :{  name:'',
            lastName:'',
            email:'',
            isAdmin: '',
            phoneNumber: '',
            address: '',
            country: '',
            zipCode: '',
            city: '',
            state: '',
        }
            }
        },     

        },
});

export const { logout, setReduxUserState } = userSlice.actions;
export default userSlice.reducer;