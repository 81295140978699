import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Badge,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
//import { logout } from "../redux/slice/userSlices";
//import { logout } from "../redux/slices/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../redux/slices/category/categorySlice";
import { setReduxUserState } from "../redux/slices/user/userSlice";
import { logout } from "../redux/slices/user/userSlice";
import axios from "axios";
const HeaderComponent = () => {
  const dispatch = useDispatch();
  // const { userInfo } = useSelector((state) => state.user);
  //const userInfo ={}

  const user = useSelector((state) => state.user.userInfo);
  // const color = useSelector(state=>state.color.value);
  // console.log(user)
  // console.log("in header ", user)
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  //const { categories } = useSelector((state) => state.getCategories);
  //const categories =  {}
  const [searchCategoryToggle, setSearchCategoryToggle] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const fetchCategory = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/categories"
    );
    return data;
  };

  useEffect(() => {
    fetchCategory().then((data) => {
      //   console.log("HeaderComponent category data ", data)

      dispatch(getCategories(data));
    });
  }, [dispatch]);

  const submitHandler = (e) => {
    if (e.keyCode && e.keyCode !== 13) return;
    e.preventDefault();

    const serchText = e.target.value;
   // console.log(serchText);
    if(serchText){
     setTimeout(() => {
      navigate(`/product-list/search/${serchText}`);
    
    }, 400);
  }
  };
  //  console.log("isAdmin ", user.isAdmin)
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand href="/">Inventory Management System</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <InputGroup>
              <DropdownButton
                id="dropdown-basic-button"
                title={searchCategoryToggle}
              >
                <Dropdown.Item onClick={() => setSearchCategoryToggle("All")}>
                  All
                </Dropdown.Item>
                {/* {categories.map((category, id) => (
                  <Dropdown.Item key={id} onClick={() => setSearchCategoryToggle(category.name)}>{category.name}</Dropdown.Item>
                ))} */}
              </DropdownButton>
              <Form.Control
                onChange={(e) => {
                  submitHandler(e);
                }}
                type="text"
                placeholder="Search in shop ..."
              />
              <Button onClick={submitHandler} variant="warning">
                <i className="bi bi-search text-dark"></i>
              </Button>
            </InputGroup>
          </Nav>
          <Nav>
            {user.isAdmin ? (
              <LinkContainer to="/admin/products">
                <Nav.Link>
                  Admin
                  <span className="position-absolute top-1 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
                </Nav.Link>
              </LinkContainer>
            ) : user.name && !user.isAdmin ? (
              <NavDropdown
                title={`${user.name} ${user.lastName}`}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  eventKey="/user/my-orders"
                  as={Link}
                  to="/user/my-orders"
                >
                  My orders
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="/user" as={Link} to="/user">
                  My profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    axios.get(process.env.REACT_APP_BASE_URL + "/api/logout"); //added by me
                    dispatch(logout());
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/register">
                  <Nav.Link>Register</Nav.Link>
                </LinkContainer>
              </>
            )}

            {/* <LinkContainer to="/cart">
              <Nav.Link>
                <Badge pill bg="danger">
                  {itemsCount === 0 ? "" : itemsCount}
                </Badge>
                <i className="bi bi-cart-dash"></i>
                <span className="ms-1">CART</span>
              </Nav.Link>
            </LinkContainer> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderComponent;
