import UserProfilePageComponent from "./components/UserProfilePageComponent";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setReduxUserState } from "../../redux/slices/user/userSlice";

const updateUserApiRequest = async (
  _id,
  name,
  lastName,
  phoneNumber,
  address,
  country,
  zipCode,
  city,
  state,
  password
) => {
  const { data } = await axios.put(process.env.REACT_APP_BASE_URL+"/api/users/profile", {
    _id,
    name,
    lastName,
    phoneNumber,
    address,
    country,
    zipCode,
    city,
    state,
    password,
  }, { withCredentials: true });
  return data;
};

const fetchUser = async (id) => {
  const { data } = await axios.get(process.env.REACT_APP_BASE_URL+"/api/users/profile/" + id, { withCredentials: true });
  return data;
};

const UserProfilePage = () => {
  const reduxDispatch = useDispatch();
  const  user  = useSelector((state) => state.user.userInfo);

  return (
    <UserProfilePageComponent
      updateUserApiRequest={updateUserApiRequest}
      fetchUser={fetchUser}
      userInfoFromRedux={user}
      setReduxUserState={setReduxUserState}
      reduxDispatch={reduxDispatch}
      localStorage={window.localStorage}
      sessionStorage={window.sessionStorage}
    />
  );
};

export default UserProfilePage;

