import axios from "axios";
import CategoryPageComponent from "./components/CategoryPageComponent";

import { uploadImagesApiRequest } from "./utils/utils";


const fetchProducts = async (abctrl) => {
    
    const { data } = await axios.get(process.env.REACT_APP_BASE_URL+"/api/categories", {
        signal: abctrl.signal,
    })
    return data; 
}

const deleteCategory = async (id) => {
    const { data } = await axios.delete(process.env.REACT_APP_BASE_URL+"/api/categories/"+id);
    return data
}

const AdminCategoryPage = () => {
  return <CategoryPageComponent fetchCategory={fetchProducts} deleteCategory={deleteCategory} uploadImagesApiRequest={uploadImagesApiRequest} />
};

export default AdminCategoryPage;

