import { createSlice } from '@reduxjs/toolkit';
const initialState = {value : "red"};

export const colorSlice = createSlice({
    name: 'color',
    initialState,
    reducers:{
    primery:(state)=>{state.value='green,red,blue'},
    secondery:(state)=>{state.value='yellow,black,white,violent'}
    }

})

export const {primery, secondery} = colorSlice.actions;
export default colorSlice.reducer;