import UserCartDetailsPageComponent from "./components/UserCartDetailsPageComponent";

import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart, orderSubmited } from "../../redux/slices/cart/cartSlice";

import axios from "axios";

const UserCartDetailsPage = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const cartSubtotal = useSelector((state) => state.cart.cartSubtotal);
  const userInfo = useSelector((state) => state.user.userInfo);
console.log("userInfo ", userInfo)
  const reduxDispatch = useDispatch();

  const getUser = async () => {
    const { data } = await axios.get(process.env.REACT_APP_BASE_URL+"/api/users/profile/" + userInfo._id);
    return data;
  };

  const createOrder = async (orderData) => {
      const { data } = await axios.post(process.env.REACT_APP_BASE_URL+"/api/orders", { ...orderData });
    // console.log(data)
      return data; 
  }

  return (
    <UserCartDetailsPageComponent
      cartItems={cartItems}
      itemsCount={itemsCount}
      cartSubtotal={cartSubtotal}
      userInfo={userInfo}
      addToCart={addToCart}
      removeFromCart={removeFromCart}
      orderSubmited={orderSubmited}
      reduxDispatch={reduxDispatch}
      getUser={getUser}
      createOrder={createOrder}
    />
  );
};

export default UserCartDetailsPage;

