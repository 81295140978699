import { Outlet, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import UserChatComponent from "./user/UserChatComponent";

import axios from "axios";
import React, { useEffect, useState } from "react";
import LoginPage from "../pages/LoginPage";

const ProtectedRoutesComponent = ({ admin }) => {
  
  const [isAuth, setIsAuth] = useState();
  const [reRender, setReRender] = useState(0);
   

  
  useEffect(() => {
   

     axios.get(process.env.REACT_APP_BASE_URL+"/api/get-token").then(function (data) {
   
       //  if (data.data.token) {
        
             setIsAuth(data.data.token);
             setReRender(1)
       //  }
      //  return isAuth;
     }) 
  }, [isAuth, reRender])


  
  if (reRender === 1 && isAuth === undefined) return <LoginPage />;

  return isAuth && admin && isAuth !== "admin" ? (
       <Navigate to="/login" />
  ) : isAuth && admin ? (
      <Outlet />
  ) : isAuth && !admin ? (
      <>
      {/* <UserChatComponent /> */}
      <Outlet />
      </>
  ) : reRender === 1  ? (<Navigate to="/login" />):<Outlet />
  
  
};

export default ProtectedRoutesComponent;

