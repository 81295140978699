import { Row, Col, Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

import { useState, useEffect } from "react";

import { logout } from "../../../redux/slices/user/userSlice";
import { useDispatch } from "react-redux";

const CategoryPageComponent = ({ fetchCategory, deleteCategory }) => {
  const [categories, setCategories] = useState([]);
   const [categoryDeleted, setCategoryDeleted] = useState(false); 
   const dispatch = useDispatch();

  const deleteHandler = async (categoryName) => {
    if (window.confirm("Are you sure?")) {
        const data = await deleteCategory(categoryName)
      console.log(data)
        if (data === "category removed") {
            setCategoryDeleted(!categoryDeleted);
        }
    }
  };

  useEffect(() => {
    const abctrl = new AbortController();
    fetchCategory(abctrl)
      .then((res) => setCategories(res))
      .catch((er) =>{
   //   dispatch(logout())
        // setCategories([
        //   {name: er.response.data.message ? er.response.data.message : er.response.data}
        // ])
  });
    return () => abctrl.abort();
  }, [categoryDeleted]);

 console.log(categories)

  return (
    <Row className="m-5">
      <Col md={2}>
        <AdminLinksComponent />
      </Col>
      <Col md={10}>
        <h1>
          Category List{" "}
          <LinkContainer to="/admin/create-new-category">
            <Button variant="primary" size="lg">
              Create new
            </Button>
          </LinkContainer>
        </h1>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Category Name</th>
              <th>Desc</th>
              <th>Img Link</th>
              <th>Edit/Delete</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((item, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{item.Category}</td>
                <td>{item.description}</td>
                <td>{item.image}</td>
                <td>
                  <LinkContainer to={`/admin/edit-product/${item.id}`}>
                    <Button className="btn-sm">
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </LinkContainer>
                  {" / "}
                  <Button 
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(item.id)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default CategoryPageComponent;

