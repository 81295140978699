import CreateProductPageComponent from "./components/CreateCategoryPageComponent";
import axios from "axios";
import {
  uploadImagesApiRequest,
  uploadImagesCategoryApiRequest,
  uploadImagesCloudinaryApiRequest,
} from "./utils/utils";
import { useSelector } from "react-redux";
import { newCategory,deleteCategory, saveAttributeToCatDoc } from "../../redux/slices/category/categorySlice";
import { useDispatch } from "react-redux";

const createProductApiRequest = async (formInputs) => {
  const { data } = await axios.post(process.env.REACT_APP_BASE_URL+"/api/categories/newCategory", { ...formInputs });
  console.log("Resposnse send create cat data ", data)
  return data;
};

const AdminCreateCategoryPage = () => {
  const { categories } = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  return (
    <CreateProductPageComponent
      createProductApiRequest={createProductApiRequest}
      uploadImagesCategoryApiRequest={uploadImagesCategoryApiRequest}
      uploadImagesCloudinaryApiRequest={uploadImagesCloudinaryApiRequest}
      categories={categories}
      reduxDispatch={dispatch}
      newCategory={newCategory}
      deleteCategory={deleteCategory}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateCategoryPage;

